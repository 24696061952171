import classNames from 'classnames';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { SVGExclamation, SVGTickCircle, SVGCautionSolid, SVGExport } from '_Icons';
import { SVGRipple } from '_Icons/loading';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';

toast.configure();

const useModalOpen = (toast) => {
  useEffect(
    () => {
      const toggleModal = () => toast.pause();
      document.addEventListener('modal-open', toggleModal);
      return () => {
        document.removeEventListener('modal-open', toggleModal);
      };
    },
    []
  );
};

const Render = ({ level, children, toast }) => {
  useModalOpen(toast);
  return(
    <span
      className={'toast-notification__text'}
      data-cy={'toast-notification-text'}
    >
      {level && (
        <>
          <span className={'toast-notification__svg'}>
            {(level == TOAST_LEVEL.LOADING) && <SVGRipple size={16} />}
            {(level == TOAST_LEVEL.INFO) && <SVGTickCircle size={16} />}
            {(level == TOAST_LEVEL.EXPORT) && <SVGExport size={16} />}
            {(level == TOAST_LEVEL.WARNING) && <SVGCautionSolid size={16} />}
            {(level == TOAST_LEVEL.ERROR) && <SVGExclamation size={16} />}
          </span>
        </>
      )}
      {children}
    </span>
  );
};

export const TOAST_LEVEL = {
  EXPORT: 'export',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  LOADING: 'loading',
};

class Toast {
  constructor(render, options = {}) {
    this.id = undefined;
    if (render) this.render(render, options);
  }

  buildOptions = (render, options) => {
    if (!render) return;

    this.options = {
      render,
      className: this.getClassName(options),
      bodyClassName: 'toast-notification__body',
      position: 'bottom-center',
      hideProgressBar: true,
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      ...options
    };
  }

  getClassName = ({ level }) => classNames(
    'toast-notification',
    {
      [`toast-notification--${level}`]: level
    }
  );

  isOpen = () => toast.isActive(this.id);

  dismiss = () => {
    if (!this.id) return;
    toast.dismiss(this.id);
    this.id = undefined;
  }

  resume = () => {
    this.render();
    this.#removeModalCloseListener();
  }

  pause = () => {
    this.dismiss();
    this.modalCloseListener = true;
    document.addEventListener('modal-close', this.resume);
  }

  render = (in_render, in_options) => {
    this.buildOptions(in_render, in_options);
    this.#removeModalCloseListener();
    const {
      render,
      level,
      ...options
    } = this.options;
    if (this.id) return toast.update(
      this.id,
      {
        render: (
          <Render level={level} toast={this}>
            {render}
          </Render>
        ),
        ...options
      }
    );
    this.id = toast(
      (
        <Render level={level} toast={this}>
          {render}
        </Render>
      ),
      options
    );
  }

  #removeModalCloseListener = () => {
    if (!this.modalCloseListener) return;
    document.removeEventListener('modal-close', this.resume);
    this.modalCloseListener = false;
  }
}

export default Toast;
