/* eslint-disable import/no-internal-modules */
import { parsePhoneNumberWithError } from 'libphonenumber-js/mobile';

export {
  eventDidBlur,
  makeRandomID,
  makeUUID,
  springDuration,
  SPRING_CONFIG_150,
  sleep,
  arraySwapIndex,
  isAsyncFunction,
  isFunction,
  isObject,
  isArray,
  isString,
  isNumber,
  humanize,
  convertServerErrorsToMap,
  undefinedToNull,
  nullToUndefined,
  resolveChildren,
  setLocationHash,
  copyToClipboard,
  formatUserLabel,
  deepMerge,
  cycleSort,
  calcColourWithAlpha,
  colourToGreyScale,
  asciiSafeText,
  safeFilename,
  locationInApp,
  accentColourForHex,
  getAriaLabel,
  luminosityForHex,
  colourContrastRatio,
  getWindowPlacement
} from '@askyourteam-nz/react-library/dist/lib';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes == 0) return '0 Bytes';
  const base = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.floor(Math.log(bytes) / Math.log(base));
  const suffix = sizes[exponent];
  const coefficient = parseFloat(bytes / Math.pow(base, exponent));

  return `${coefficient.toFixed(decimals)} ${suffix}`;
};

export const phonenumberValidator = inp => {
  let text = inp, error;
  try {
    const pn = parsePhoneNumberWithError(inp);
    if (!pn.countryCallingCode) throw new Error('International Format Required');
    if (pn.getType() != 'MOBILE') throw new Error('Cell Numbers Required');
    if (pn.isValid()) text = pn.number;
  } catch (e) {
    error = (message => {
      switch (message) {
        case 'INVALID_COUNTRY': return 'Invalid country';
        case 'NOT_A_NUMBER': return 'Not a phone number';
        case 'TOO_LONG': return 'Number is too long';
        case 'TOO_SHORT': return 'Number is too short';
      }
    })(e.message);
  }
  return [text, error];
};

export const sortAlphabetically = (a = '', b = '', desc_inverse) => {
  let sortedValue = (a.toLowerCase() < b.toLowerCase()) ? -1 : 1;
  if (desc_inverse) sortedValue *= -1;
  return sortedValue;
};

export const pluralize = (word, number) => number == 1 ? word : `${word}s`;

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 */
export const hashCode = (str) => {
  let hash = 13;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
/* eslint-enable import/no-internal-modules */
