import Log from './Log';
import Toast, { TOAST_LEVEL } from './Toast';
import OAuth from './OAuth';
import { ERROR_LEVEL } from './Enum';

class ManageAsyncPromise {

  constructor(settings) {
    this.toast = new Toast();
    this.options = {
      position: 'bottom-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnVisibilityChange: false,
      newestOnTop: false,
      draggable: false
    };
    if (settings) this.start(settings);
  }

  close = () => {
    this.toast.dismiss();
    if (this.api) {
      this.api.oauth.abort();
      this.api.current = false;
    }
  }

  onErrorRender = ({ children, level = TOAST_LEVEL.ERROR, ...rest }) => {
    this.toast.render(
      children,
      {
        onClick: () => this.reload(),
        level,
        ...this.options,
        ...rest
      }
    );
  }

  onStartRender = ({ children, level = TOAST_LEVEL.LOADING, ...rest }) => {
    this.toast.render(
      children,
      {
        level,
        ...this.options,
        ...rest
      }
    );
  }

  onResultRender = (props, { onClick = () => {}, data }) => {
    const {
      children,
      level = TOAST_LEVEL.INFO,
      ...rest
    } = props;

    this.toast.render(
      children,
      {
        onClick: async () => {
          try {
            await onClick(data);
          } catch(error) {
            Log.breadcrumb('Promise caught', error.message, ERROR_LEVEL.ERROR);
            Log.error(error);
          }
          this.close();
        },
        level,
        ...this.options,
        ...rest
      }
    );
  }

  reload = () => {
    this.start(this.settings);
  }

  start = async (settings) => {
    this.close();
    this.api = {
      oauth: new OAuth(),
      current: true
    };
    let api = this.api;

    this.settings = settings;
    const {
      fetchData,
      options,
      onStartDisplayProps = () => ({}),
      onErrorDisplayProps = () => ({}),
      onResultDisplayProps = () => ({}),
      onClick
    } = settings;

    this.options = {
      ...this.options,
      ...options
    };

    this.onStartRender(onStartDisplayProps({}));

    let data;
    try {
      data = await fetchData(api);
    } catch (error) {
      if (!api.current) return;
      Log.breadcrumb('Promise caught', error.message, ERROR_LEVEL.ERROR);
      Log.error(error);
      this.onErrorRender({
        children: error.message,
        ...onErrorDisplayProps({ error })
      });
      return;
    }

    if (!api.current) return;
    this.onResultRender(
      onResultDisplayProps({ data }),
      {
        data,
        onClick
      }
    );
  }
}

export default ManageAsyncPromise;
