import Cookies from 'universal-cookie';
import axios from 'axios';
import { isObject, isArray } from './Utils';
// eslint-disable-next-line import/no-internal-modules
import { OAuth as LibraryOauth } from '@askyourteam-nz/react-library/dist/lib';

const cookies = new Cookies();

export default class OAuth extends LibraryOauth {
  constructor () {
    super();
  }

  static get CSRFToken() {
    return document.querySelector('meta[name=csrf-token]').getAttribute('content');
  }

  static get topLevelDomain() {
    const domainParts = location.hostname.split('.').reverse();
    return `${domainParts[1]}.${domainParts[0]}`;
  }

  static isTokenValid({ created_at = 0, expires_in = 0 } = {}) {
    const currentDate = new Date() / 1000;
    const tokenExpiresAt = created_at + expires_in;
    return tokenExpiresAt > currentDate;
  }

  static get token() {
    const doorkeeper_token = cookies.get('doorkeeper_token');
    if (!doorkeeper_token) return false;
    const token = JSON.parse(decodeURI(doorkeeper_token));

    if (OAuth.isTokenValid(token)) {
      return token;
    } else {
      OAuth.cleanCookies();
      return false;
    }
  }

  static cleanCookies () {
    cookies.remove('doorkeeper_token', { path: '/', domain: OAuth.topLevelDomain });
  }

  setToken(token) {
    let date = new Date();
    date.setTime(date.getTime() + (token.expires_in*1000));
    cookies.set(
      'doorkeeper_token',
      encodeURI(JSON.stringify(token)),
      {
        path: '/',
        domain: OAuth.topLevelDomain,
        secure: !process.env.INSECURE_COOKIES,
        expires: date
      }
    );
    return true;
  }

  async logout() {
    await this.deleteAPI(
      '/logout',
      {
        token: OAuth.token.access_token
      }
    );
    return OAuth.cleanCookies();
  }

  postFormData = (url, data = {}, config = {}) => {
    const instance = axios.create();

    instance.interceptors.response.use(
      response => {
        this.afterCallback(response.status);
        return this.interceptSuccess(response);
      },
      error => {
        this.afterCallback(error?.response?.status);
        return this.interceptFailure(error);
      }
    );

    let form = new FormData();
    const appendObject = (key, value) => {
      if (isObject(value)) {
        Object.entries(value)
          .forEach(
            ([mapKey, value]) => appendObject(`${key}[${mapKey}]`, value)
          );
        return;
      }
      if (isArray(value)) {
        value.forEach(
          value => appendObject(`${key}[]`, value)
        );
        return;
      }
      form.append(key, value);
    };
    appendObject('', data);

    this.beforeCallback();
    return instance({
      ...config,
      url,
      data: form,
      baseURL: this.domain,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${OAuth.token.access_token}`,
        'Accept': '*/*;q=0.5, text/javascript, application/javascript, application/ecmascript, application/x-ecmascript',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-CSRF-Token': OAuth.CSRFToken,
        'X-Requested-With': 'XMLHttpRequest',
        ...config.headers
      },
      cancelToken: this.source.token
    });
  }

}
