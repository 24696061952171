import { Log as LibraryLog } from '@askyourteam-nz/react-library/dist/lib'; // eslint-disable-line import/no-internal-modules

export const formatTime = time => {
  const pad = (num, maxLength) => {
    const paddedZeros = new Array(maxLength - num.toString().length).fill('0');
    return paddedZeros + num;
  };
  return `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(time.getMilliseconds(), 3)}`;
};

export const spread = state => Object.keys(state).flatMap(key => [key, state[key], '\n          ']);

export const titleColor = action => {
  if (action.match(/_begin$/i)) return '#e28009';
  if (action.match(/_success$/i)) return '#6cdb11';
  if (action.match(/_finished$/i)) return '#6cdb11';
  if (action.match(/_failure$/i)) return '#db3211';
  if (action.match(/_change$/i)) return '#0499d8';
  if (action.match(/_clear$/i)) return '#8bdff4';
  return '#4a4a4a';
};

class Log extends LibraryLog {
  static funcWrapper (func, level) {
    if (process.env.JS_LOGGER === '1') return func;
    if (APP_ENV == 'staging' && level == 1) return func;
    return super.funcWrapper(func, level);
  }
}

export default Log;
