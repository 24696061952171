import OAuth from './OAuth';
import idleTimeout from 'idle-timeout';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class TokenWatcher extends OAuth {
  constructor() {
    super();
    this.renewTime = OAuth.token.expires_in * 0.10; // renew if token expire in 10% of expires_in time
    this.repeatTime = 30000; // 30s
    this.watcher();
    this.autoLogOut();
    this.renewLastAccessedAtOnActivity();
  }

  autoLogOut() {
    this.setLastAccessedAt();
    if (process.env.NODE_ENV != 'development') this.autoLogOutInstance = idleTimeout(
      () => {
        // auto log out
        this.idleTimeoutCallback();
      },
      {
        element: document,
        timeout: 1 * 60 * 60 * 1000, // 1 hour
        loop: false
      }
    );
  }

  setLastAccessedAt() {
    let date = new Date();
    const hour = (1*60*60*1000);
    date.setTime(date.getTime() + hour);
    cookies.set(
      'last_accessed_at',
      (new Date()).getTime(),
      {
        path: '/',
        domain: OAuth.topLevelDomain,
        secure: !process.env.INSECURE_COOKIES,
        expires: date
      }
    );
  }

  idleTimeoutCallback() {
    let lastAccessedAt = cookies.get('last_accessed_at');
    let currentTimestamp = (new Date()).getTime();
    // diff bigger than 1 hour
    if (!lastAccessedAt || currentTimestamp - parseInt(lastAccessedAt) >= 1 * 60 * 60 * 1000) {
      this.logout();
      window.history.pushState({}, undefined, '/login');
      window.location.reload();
    } else {
      this.autoLogOutInstance.reset();
    }
  }

  renewLastAccessedAtOnActivity() {
    if (!this.autoLogOutInstance) return;
    this.autoLogOutInstance.eventNames
      .forEach(
        eventName => {
          document.addEventListener(eventName, this.setLastAccessedAt);
        }
      );
  }

  watcher() {
    const token = OAuth.token;
    if (!token) return;

    if (!OAuth.isTokenValid(token)) {
      OAuth.cleanCookies();
      return;
    }

    if (this.shouldRenewToken(token)) this.renewOauthToken();
    setTimeout(
      this.watcher.bind(this),
      this.repeatTime
    );
  }

  renewOauthToken() {
    this
      .postAPI(
        '/oauth/token',
        {
          grant_type: 'refresh_token',
          refresh_token: OAuth.token.refresh_token
        }
      )
      .then(response => {
        OAuth.cleanCookies();
        return this.setToken(response);
      });
  }

  shouldRenewToken({ created_at = 0, expires_in = 0 } = {}) {
    const currentDate = new Date() / 1000;
    const tokenExpiresAt = created_at + expires_in;
    return tokenExpiresAt <= (currentDate + this.renewTime);
  }
}

export default TokenWatcher;
